









import Vue from 'vue';
import { mapGetters } from 'vuex';
import ApiService from '@/common/api.service';
import SurveyComponent from '@/components/elements/SurveyComponent.vue';
import { endpoints } from '@/common/config';
import { processResponseError } from '@/common/misc';

export default Vue.extend({
    name: 'quizView',
    data(){
        return {
            surveyData: {},
            displaySurvey: false,
        }
    },
    components: {
        SurveyComponent,
    },
    async mounted(){
        if(!this.isAuthenticated){ 
            this.$modal.show('dialog', {
                title: 'Ошибка при прохождении опроса',
                text: 'Для прохождения опроса необходимо авторизоваться',
                buttons: [
                    // {
                    //     title: 'Ок',
                    //     handler: () => this.$modal.hideAll(),
                    // },
                    {
                        title: 'Войти',
                        handler: () => this.$router.push('/login')
                    }
                ],
            });
            // alert('Только авторизованные пользователи могут проходить опросы');
            return;
        }

        const {take_id} = this.$route.query;

        if(!take_id) {
            this.$modal.show('dialog', {
                title: 'Ошибка при прохождении опроса',
                text: 'URL Параметр take_id не указан',
                buttons: [
                    {
                        title: 'На главную',
                        handler: () => this.$router.push('/')
                    }
                ]
            })
            return;
        }

        if(typeof take_id != 'string') return;
        let response:any;
        try {
            response = await ApiService.get(endpoints.getStartQuiz(take_id));
        } catch (error) {
            alert(processResponseError(error));
            return;
        }
        this.surveyData = response.data.quiz;
    },
    computed: {
        ...mapGetters(['activeNavDestination', 'isAuthenticated'])
    }
})
