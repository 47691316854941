import { render, staticRenderFns } from "./QuizView.vue?vue&type=template&id=775af1ff&scoped=true&"
import script from "./QuizView.vue?vue&type=script&lang=ts&"
export * from "./QuizView.vue?vue&type=script&lang=ts&"
import style0 from "./QuizView.vue?vue&type=style&index=0&id=775af1ff&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "775af1ff",
  null
  
)

export default component.exports